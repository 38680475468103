<template>
    <nav>
        <div class="nav-left">
            <router-link to="/" class="nav-link">Home</router-link>
            <router-link to="/manage" class="nav-link">Manage Campaigns</router-link>
            <router-link to="/about" class="nav-link">About</router-link>
        </div>
        <div class="nav-right">
            <div v-if="user" class="user-section">
                <!-- Combine image and button -->
                <button @click="logout" class="user-button">
                    <img v-if="user.photoURL" :src="user.photoURL" alt="User Avatar" class="avatar" />
                    {{ user.displayName }} (Logout)
                </button>
            </div>
            <div v-else class="login-section">
                <router-link to="/login">Login</router-link>
            </div>
        </div>
    </nav>
</template>

<script>
import { inject } from 'vue';
import { auth } from '@/js/firebase';
import { signOut } from "firebase/auth";

export default {
    name: 'NavBar',
    setup() {
        const user = inject('user');

        const logout = () => {
            signOut(auth);
        };

        return { user, logout };
    }
}
</script>

<style scoped>
nav {
    background-color: #f6f6f6;
    padding: 1em 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-left,
.nav-right {
    display: flex;
    align-items: center;
}

.nav-link {
    margin-right: 15px;
    text-decoration: none;
    color: inherit;
    padding: 0.5em;
    transition: background-color 0.3s;
}

.nav-link:hover {
    background-color: #e6e6e6;
}

.router-link-active {
    background-color: #e0e0e0; /* subtle grey background */
    color: #424242; /* darker text color */
    border-radius: 4px; /* optional: rounded corners */
    padding: 5px 10px; /* optional: some padding */
}
.user-section {
    display: flex;
    align-items: center;
}

.user-button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5em;
    transition: background-color 0.3s;
}

.user-button:hover {
    background-color: #e6e6e6;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 0.5em;
}

.login-section {
    margin-left: 15px;
    /* Adjust to desired spacing */
}
</style>