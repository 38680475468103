import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/js/router';
import { firebaseApp, auth, db } from '@/js/firebase';

const app = createApp(App)
    .use(router)

app.config.globalProperties.$firebase = firebaseApp; // Make Firebase accessible globally if needed
app.config.globalProperties.$auth = auth; // Make Firebase Auth accessible globally if needed
app.config.globalProperties.$db = db; // Make Firebase Firestore accessible globally if needed

app.mount('#app');

export default app;