import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        component: () => import('@/components/HomePage.vue')
    },
    {
        path: '/login',
        component: () => import('@/components/FirebaseLogin.vue'),
    },
    {
        path: '/manage',
        component: () => import('@/components/ManageCampaign.vue'),
    },
    {
        path: '/about',
        component: () => import('@/components/AboutApp.vue')
    },
    // ... other routes
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
