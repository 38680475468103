// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";


// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBBBpri0ly-us02_JsAZGXAUXrf5bZU13k",
    authDomain: "pickupit.firebaseapp.com",
    projectId: "pickupit",
    storageBucket: "pickupit.appspot.com",
    messagingSenderId: "314093714477",
    appId: "1:314093714477:web:d7feabace4177d788f8ca4"
};

function createFirebaseApp() { return initializeApp(firebaseConfig) }
const firebaseApp = createFirebaseApp();
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
//https://stackoverflow.com/questions/60536897/how-to-tell-a-vue-app-to-use-firebase-emulator
// If on localhost, use all firebase services locally
if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    connectFirestoreEmulator(db, 'localhost', 9079);
    connectAuthEmulator(auth, "http://localhost:9099");
    // add more services as described in the docs: https://firebase.google.com/docs/emulator-suite/connect_firestore
}

export { firebaseApp, auth, db }
