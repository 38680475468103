<template>
  <NavBar />
  <img class="logo" alt="Pickup Logo" src="@/assets/pickup-truck.png">
  <router-view />
</template>

<script>
import { ref, onMounted, provide } from 'vue';
import NavBar from '@/components/NavBar.vue';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '@/js/firebase';

export default {
  name: 'App',
  components: {
    NavBar
  },
  setup() {
    const user = ref(null);

    onMounted(() => {
      onAuthStateChanged(auth, (currentUser) => {
        user.value = currentUser;
      });
    });

    provide('user', user);

    return {};
  }
}
</script>

<style scoped>
.logo {
  height: 50px;
  width: 50px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.nav-link {
  margin-right: 15px;
  text-decoration: none;
  color: inherit;
}

.nav-link:hover {
  text-decoration: underline;
}
</style>
